import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"

const Ueberdachungen = () => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: { relativeDirectory: { eq: "ueberdachungen" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 75, width: 1300)
            }
          }
        }
      }
      thumbnails: allFile(
        filter: { relativeDirectory: { eq: "ueberdachungen" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
        }
      }
    }
  `)

  return (
    <SimpleReactLightbox>
      <SRLWrapper
        options={{
          settings: {
            lightboxTransitionSpeed: 0.2,
            slideTransitionSpeed: 0.2,
          },
          buttons: { showDownloadButton: false },
          caption: {
            showCaption: false,
          },
        }}
      >
        <div className="container max-w-screen-lg xl:max-w-screen-xl sm:mx-auto sm:px-6 bg-white">
          <div className="grid grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3">
            {data.thumbnails.edges.map((edge, index) => {
              return (
                <a
                  href={
                    data.images.edges[index].node.childImageSharp
                      .gatsbyImageData.images.fallback.src
                  }
                  key={index}
                  className="h-80 sm:h-64"
                >
                  <GatsbyImage
                    className="h-full"
                    image={edge.node.childImageSharp.gatsbyImageData}
                    alt={edge.node.name}
                  />
                </a>
              )
            })}
          </div>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default Ueberdachungen
