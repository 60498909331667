import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Ueberdachungen from "../queries/ueberdachungen"

import ServiceAndProcedure from "../components/serviceAndProcedure"

const Index = () => {
  const { sliderImage } = useStaticQuery(
    graphql`
      query {
        sliderImage: file(
          relativePath: { eq: "slider/header_ueberdachung.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    `
  )
  const image = getImage(sliderImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout spacing="true">
      <Seo
        title="Überdachungen"
        description="Erweitern Sie Ihren Wohnraum mit einer Überdachung aus Aluminium, Stahl, Glas oder Kunststoff."
      />

      <div className="flex w-full h-screen">
        <BackgroundImage
          {...bgImage}
          style={{ width: "100%" }}
          className="h-full w-full flex items-center"
        >
          <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-72">
            <h1 className="text-4xl md:text-5xl font-bold mb-1.5">
              Überdachungen
            </h1>
            <p className="max-w-4xl text-xl mt-1">
              Erweitern Sie Ihren Wohnraum mit einer Überdachung aus Aluminium,
              Stahl, Glas oder Kunststoff.
            </p>
          </div>
        </BackgroundImage>
      </div>
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 bg-white">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center lg:order-last">
            <img
              src="/zeichnungen/ueberdachung.svg"
              className="block h-56"
              alt="Technische Zeichnung eines Balkons"
            />
          </div>
          <div className="flex items-center">
            <p>
              Überdachungen schützen die darunter befindlichen Gegenstände vor
              den Einflüssen des Wetters. Darüber hinaus wertet es die Immobilie
              optisch auf. Vor der Tür, im Garten, für das Auto oder Motorrad,
              die Überdachungen werden zentimetergenau und nach Ihren
              Vorstellungen maßgefertigt. Hierfür bieten wir eine vielfältige
              Auswahl, damit sich die Überdachung harmonisch in die Umgebung
              einfügt.
            </p>
          </div>
        </div>
      </div>
      <ServiceAndProcedure />
      <div className="pb-16">
        <h2 className="w-full text-4xl md:text-5xl text-center mb-6 text-gray-800">
          Galerie
        </h2>
        <Ueberdachungen />
      </div>
    </Layout>
  )
}

export default Index
